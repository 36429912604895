$small-box-shadow: 0px 170px 68px rgba(0, 0, 0, 0.01), 0px 96px 57px rgba(0, 0, 0, 0.05), 0px 42px 42px rgba(0, 0, 0, 0.09), 0px 11px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$big-box-shadow: 0px 231px 92px rgba(0, 0, 0, 0.01), 0px 130px 78px rgba(0, 0, 0, 0.05), 0px 58px 58px rgba(0, 0, 0, 0.09), 0px 14px 32px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$white: #FAFAFA;
$radius:12px;
$main-color:#E63103;
$black:#111111;
$grey: #CFC4C4;
$light-grey: #5E5E5ECC;
$small-btn-padding: 18px 30px;
$big-btn-padding: 16px 40px;

.history-bg{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .history{
        .a{
            font-size: 2rem; 
            font-weight: 600;
            margin-bottom: 2rem;
        }
        .b{
            font-size: .95rem;
            line-height: 1.5;
            font-weight: 500;
            margin-bottom: 1.5rem;
            max-width: 35rem;
            
        }
        .c{
            button{
                font-size: 1rem; 
                font-weight: 600;
                padding: $big-btn-padding;
                border: none;
                background-color: $main-color;
                border-radius: $radius;
                color: $white;
                transition: 0.3s all ease;
                margin-top: 1rem;
                cursor: pointer;

                &:hover{
                 background-color: $white;
                 border: 1px solid $main-color;
                 color: $main-color;
                }
            }
        }
    }
}
.carousel{
    margin-top: 2rem;
    
    div{
        display: flex;
        font-size: calc(15vw + 5vh);;
        font-weight: 700;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        padding-right: 100%;
        
        span{
            background: linear-gradient(to left, #fc4d36, #778efd, #FC4C35, #758BFD);
            background-clip: text;
            -webkit-text-fill-color: #fff;
            color: transparent;
            -webkit-text-stroke: 5px transparent;
            will-change: transform;
            animation: carousel 18s linear infinite;
            &:first-of-type{
                margin-right: 2rem;
            }

        }
        @keyframes carousel {
            0%{
                transform: translateX(0);
            }
            100%{
                transform: translateX(-100%);
            }
        }
    }
}
