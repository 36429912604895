$small-box-shadow: 0px 170px 68px rgba(0, 0, 0, 0.01), 0px 96px 57px rgba(0, 0, 0, 0.05), 0px 42px 42px rgba(0, 0, 0, 0.09), 0px 11px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$big-box-shadow: 0px 231px 92px rgba(0, 0, 0, 0.01), 0px 130px 78px rgba(0, 0, 0, 0.05), 0px 58px 58px rgba(0, 0, 0, 0.09), 0px 14px 32px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$white: #FAFAFA;
$radius:12px;
$main-color:#E63103;
$black:#111111;
$grey: #CFC4C4;
$light-grey: #5E5E5ECC;
$small-btn-padding: 18px 30px;
$big-btn-padding: 16px 40px;

.intros-bg{
   padding: 0 2rem;
   transform: translateY(-3rem);

   .section1{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .box{
            background-color: $white;
            border-radius: $radius;
            padding: 1.5rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: $small-box-shadow;

            .b1{
                display: flex;
                align-items: center;
                align-self: flex-start;
                font-size: 2rem;
                font-weight: 600;
                gap: .5rem;
                text-align: justify;
            }
            .b2{
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 1rem;
                line-height: 1.4;
            }
            .b3{
                button{
                   font-size: 1rem; 
                   font-weight: 600;
                   padding: $big-btn-padding;
                   border: none;
                   background-color: $main-color;
                   border-radius: $radius;
                   color: $white;
                   transition: 0.3s all ease;
                    cursor: pointer;
                   &:hover{
                    background-color: $white;
                    border: 1px solid $main-color;
                    color: $main-color;
                   }
                }
            }
        }
    }
    .section2{
        margin-top: 3rem;
        background-color: $white;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        border-radius: $radius;
        box-shadow: $big-box-shadow;
        position: relative;
        z-index: 2;

        .part1{
            display: flex;
            flex-direction: column;
            .a{
                svg{
                    height: 4rem;
                }
            }
            .b{
                font-size: 2rem;
                font-weight: 600;
            }
            .c{
                margin-top: 1rem;
                margin-bottom: .5rem;
                .c-one{
                    font-size: 1rem;
                    font-weight: 700;
                    margin-bottom: .5rem;
                }
                .c-two{
                    ul{
                        padding-left: 1rem;
                        li{
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
            .d{
                align-self: center;
                button{
                    font-size: 1rem; 
                    font-weight: 600;
                    padding: $big-btn-padding;
                    border: none;
                    background-color: $main-color;
                    border-radius: $radius;
                    color: $white;
                    transition: 0.3s all ease;
                    margin-top: 1rem;
                    cursor: pointer;
 
                    &:hover{
                     background-color: $white;
                     border: 1px solid $main-color;
                     color: $main-color;
                    }
                }
            }
        }
        .part2{
            img{
                display: block;
                height: 100%;
                width: 100%;
            }
            // height: 50rem;
        }
    }
}

@media screen and (min-width: 950px) {
    .intros-bg{
        .section1{
            flex-direction: row;
            .box{
                align-items: flex-start;
            }
        }
        .section2{
            flex-direction: row;
            
            .part1{
                width: 60%;

                .d{
                    align-self: flex-start;
                }
            }
            .part2{
                padding-top: 4rem;
                width: 50%;
                
            }
        }
    }
}

@media screen and (min-width:1200px){
    .intros-bg{
        padding: 0 7.5rem;
    }
}