$small-box-shadow: 0px 170px 68px rgba(0, 0, 0, 0.01), 0px 96px 57px rgba(0, 0, 0, 0.05), 0px 42px 42px rgba(0, 0, 0, 0.09), 0px 11px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$big-box-shadow: 0px 231px 92px rgba(0, 0, 0, 0.01), 0px 130px 78px rgba(0, 0, 0, 0.05), 0px 58px 58px rgba(0, 0, 0, 0.09), 0px 14px 32px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
$white: #FAFAFA;
$radius:12px;
$main-color:#E63103;
$black:#111111;
$grey: #CFC4C4;
$date-colors: #6B7280;
$light-grey: #5E5E5ECC;
$small-btn-padding: 18px 30px;
$big-btn-padding: 16px 40px;

.news-bg{
    .section1{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0 2rem;
        transform: translateY(-2rem);
        .box{
            background-color: $white;
            border-radius: $radius;
            box-shadow: $small-box-shadow;
            padding: 1rem;

            .a{
                margin-bottom: 1.5rem;
            }
            .b{
                display: inline-flex;
                gap: 1rem;
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 1rem;
                span{
                    &:first-of-type{
                        color: $date-colors;
                    }
                    &:last-of-type{
                        color: $main-color;
                        text-decoration: underline;
                    }
                }
            }
            .c{
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            .d{
                color: $date-colors;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 1rem;
            }
            .e{
                button{
                    background: none;
                    display: inline-flex;
                    align-items: flex-end;
                    border: none;
                    font-size: 1rem;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover{
                        color: $main-color;
                    }
                }
            }
        }
    }
    .section2{
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        button{
            font-weight: 600;
            border: none;
            color: $white;
            border-radius: $radius;
            background-color: $main-color;
            padding: $big-btn-padding;
            transition: 0.3s all ease;
            cursor: pointer;

            &:hover{ 
                background-color: $white;
                border: 1px solid $main-color;
                color: $main-color;
            }
        }
    }
}

@media screen and (min-width:900px){
    .news-bg{

        .section1{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            transform: translateY(-4rem);
        }
    }
}
@media screen and (min-width:1200px){
    .news-bg{
        padding: 0 7.5rem;
        .section1{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            transform: translateY(-5rem);
            .box{
                &:nth-of-type(2){
                    transform: translateY(5rem);
                }
                &:nth-of-type(5){
                    transform: translateY(5rem);
                }
            }
        }
        .section2{
            margin-top: 4rem;
        }
    }
}